import { BaseDto } from "@/shared/dtos/base-dto";
import { config_gaugesDto } from "./config_gaugesDto";

export class dashboardCreationDto extends BaseDto {
  public nombre!: string;
  public puntuacion!: number;
  public numero_de_encuestados!: number;
  public puntuacion100!: number;
  public puntuacion_maxima!: number;
  public configuracion!:config_gaugesDto[];
}
