import { store } from "@/store/store";
import { dashboard } from "@/shared/dtos/dashboard";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { dashboardCreationDto } from "@/shared/dtos/dashboardCreationDto";

@Module({
  namespaced: true,
  name: "dashboardModule",
  store,
  dynamic: true,
})
class dashboardModule extends VuexModule {
  public dashboards: dashboard[] = [];
  public dashboard: dashboard = new dashboard();
  public gauges_dashboard: dashboardCreationDto[] = [];
  public gauge_general: dashboardCreationDto = new dashboardCreationDto();
  public ids_dashboards: number[] = [];
  public gauge_general_ayuntamiento: dashboardCreationDto[] = [];
  public gauges_dashboard_ayuntamientos: [][] = [];

  @Action({ commit: "onGetdashboards" })
  public async getdashboards() {
    return await ssmHttpService.get(API.dashboard);
  }

  @Action({ commit: "onGetdashboard" })
  public async getdashboard(id: any) {
    return await ssmHttpService.get(API.dashboard + "/" + id);
  }

  @Action({ commit: "onGetgaugesdashboard" })
  public async getgaugesdashboards() {
    return await ssmHttpService.get(API.dashboard + "/dashboards/");
  }

  @Action({ commit: "onGetgaugesdashboard" })
  public async getgaugesdashboards_filtado(data: {
    agrupaciones: any;
    ayuntamientos: any;
  }) {
    return await ssmHttpService.post(
      API.dashboard + "/dashboards/",
      JSON.stringify(data)
    );
  }

  @Action({ commit: "onGetgauge_general" })
  public async getgauge_general() {
    return await ssmHttpService.get(API.dashboard + "/dashboard_general/");
  }

  @Action({ commit: "onGetgauge_general" })
  public async getgauge_general_filtado(data: {
    agrupaciones: any;
    ayuntamientos: any;
  }) {
    return await ssmHttpService.post(
      API.dashboard + "/dashboard_general/",
      JSON.stringify(data)
    );
  }

  @Action({ commit: "onGetgauge_general_ayuntamiento" })
  public async getgauge_general_ayuntamiento(data: {
    agrupaciones: any;
    ayuntamientos: any;
  }) {
    return await ssmHttpService.post(
      API.dashboard + "/dashboard_general_comparacion/",
      JSON.stringify(data)
    );
  }

  @Action({ commit: "onGetgaugesdashboards_ayuntamiento" })
  public async getgaugesdashboards_ayuntamiento(data: {
    agrupaciones: any;
    ayuntamientos: any;
  }) {
    return await ssmHttpService.post(
      API.dashboard + "/dashboard_comparacion/",
      JSON.stringify(data)
    );
  }

  @Action({ commit: "onGetidsdashboards_ayuntamiento" })
  public async getidsdashboards_ayuntamiento() {
    return await ssmHttpService.get(API.dashboard + "/ids_dashboard/");
  }

  @Action
  public async guardardashboard(dashboard: dashboard) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.dashboard, dashboard.toJson());
  }

  @Action
  public async modificardashboard(dashboard: dashboard) {
    console.log(JSON.stringify(dashboard));
    return await ssmHttpService.put(
      API.dashboard + "/" + dashboard.id,
      dashboard
    );
  }

  @Action
  public async eliminardashboard(dashboard: dashboard) {
    return await ssmHttpService.delete(
      API.dashboard + "/" + dashboard.id,
      null,
      false
    );
  }

  @Mutation
  public onGetdashboards(res: dashboard[]) {
    this.dashboards = res ? res.map((x) => new dashboard(x)) : [];
  }

  @Mutation
  public onGetgaugesdashboard(res: dashboardCreationDto[]) {
    this.gauges_dashboard = res;
  }
  @Mutation
  public onGetgaugesdashboards_ayuntamiento(res: [][]) {
    this.gauges_dashboard_ayuntamientos = res;
  }

  @Mutation
  public onGetgauge_general(res: dashboardCreationDto) {
    this.gauge_general = res;
  }
  @Mutation
  public onGetgauge_general_ayuntamiento(res: dashboardCreationDto[]) {
    this.gauge_general_ayuntamiento = res;
  }

  @Mutation
  public onGetdashboard(res: dashboard) {
    this.dashboard = new dashboard(res);
  }

  @Mutation
  public onGetidsdashboards_ayuntamiento(res: number[]) {
    this.ids_dashboards = res;
  }
}
export default getModule(dashboardModule);
