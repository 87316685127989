import { BaseDto } from "@/shared/dtos/base-dto";
import { config_gaugesDto } from "./config_gaugesDto";

export class dashboard extends BaseDto {
  public nombre!: string;
  public createdby!: number;
  public updatedby!: number;
  public datecreated!: Date;
  public dateupdated!: Date;
  public configuracion!: config_gaugesDto[];
}
